export default () => {
  const expandButtons = document.querySelectorAll('.review-cart__review > .expand-button')
  const reviewWrappers = document.querySelectorAll('.review-cart__text-wrapper')

  if (expandButtons.length > 0) {
    Array.from(expandButtons).forEach(btn => {
      btn.addEventListener('click', (e) => {
        e.target.closest('.review-cart__review').classList.toggle('review-cart__review--expand')

        if (btn.dataset.text === 'Читать полностью') {
          btn.dataset.text = 'Cкрыть'
        } else if (btn.dataset.text === 'Cкрыть') {
          btn.dataset.text = 'Читать полностью'
        } else {
          return
        }
      })
    })
  }

  if (reviewWrappers.length > 0) {
    Array.from(reviewWrappers).forEach(wrapper => {
      let lineClamp = window.getComputedStyle(wrapper, null).getPropertyValue('-webkit-line-clamp')
      let lineCount = 0

      if (wrapper.querySelectorAll('p').length > 0) {
        wrapper.querySelectorAll('p').forEach(p => {
          lineCount += countLines(p)
        })
      }

      if ((lineCount - 1) > lineClamp) {
        wrapper.parentElement.classList.add('review-cart__review--with-btn')
      }
    })
  }


  function countLines(el) {
    let lineHeight = parseInt(window.getComputedStyle(el, null).getPropertyValue('line-height'))
    return (el.offsetHeight / lineHeight)
  }
}

const seoExpandButton = document.querySelectorAll('.seo-section__expand')
seoExpandButton.forEach(button => {
  button.addEventListener('click', (e) => {
    const seoText = e.target.closest('.seo-section__content').querySelector('.seo-section__text')
    const seoDoubleText = e.target.closest('.seo-section__content')
      .querySelector('.seo-section__text.double-section__text')

    if (seoDoubleText && !seoDoubleText.classList.contains('seo-section__text__expanded')) {
      seoDoubleText.style.webkitLineClamp = 'inherit'
      seoDoubleText.style.overflow = 'visible'
    } else if (seoDoubleText) {
      seoDoubleText.style.webkitLineClamp = '13'
      seoDoubleText.style.overflow = 'hidden'
    }

    seoText.classList.toggle('seo-section__text__expanded')

    if (seoText.classList.contains('seo-section__text__expanded')) {
      e.target.dataset.text = 'Скрыть'
    } else {
      e.target.dataset.text = 'Читать полностью'
    }
  })
})
